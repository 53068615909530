



















import supportsWebP from 'supports-webp'

export default {
    beforeMount(): void {
        supportsWebP.then((supported) => {
            if (!supported) {
                document.getElementsByTagName('html')[0].classList.add('webp-not-supported')
            }
        })
    },
    computed: {
        browserSupported: (): boolean => !navigator.userAgent.includes('MSIE') && !navigator.userAgent.match(/Trident/),
    },
}
