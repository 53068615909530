



















































import skills from '@/assets/data/skillset.json'
import IconLoader from '@/components/IconLoader.vue'

export default {
    components: { IconLoader },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: () => {
        return {
            skills: skills,
            currentSkillView: null,
        }
    },
}
