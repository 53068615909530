







































export default {
    computed: {
        dateBasedFacts: (): { passionateFor: number; age: number } => {
            const today = new Date()
            const dob = new Date('1998-11-27')
            const passionateSince = new Date(2014, 0)
            const msToYearConversion = 1000 * 60 * 60 * 24 * 365
            return {
                passionateFor: Math.floor((today.getTime() - passionateSince.getTime()) / msToYearConversion),
                age: Math.floor((today.getTime() - dob.getTime()) / msToYearConversion),
            }
        },
    },
}
