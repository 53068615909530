






export default {
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    data: () => {
        return {
            svgs: {
                terraform: require('@/assets/svg/terraform.svg'),
                mongodb: require('@/assets/svg/mongodb.svg'),
            },
        }
    },
    props: {
        icon: [String, Array],
    },
}
