<template>
    <div>
        <Header />
        <About />
        <Skills />
        <Contact />
    </div>
</template>
<script>
import Header from '../components/Home/Header'
import About from '../components/Home/About'
import Skills from '../components/Home/Skills'
import Contact from '../components/Home/Contact'

export default {
    components: { Contact, Skills, About, Header },
}
</script>
