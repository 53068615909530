import Vue from 'vue'
import VueRouter from 'vue-router'
import router from '@/router'
import App from './App.vue'
import Buefy from 'buefy'
import { library } from '@fortawesome/fontawesome-svg-core'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
    faXing,
    faInstagram,
    faLinkedin,
    faGitlab,
    faPhp,
    faJava,
    faNode,
    faJs,
    faDocker,
    faVuejs,
    faAws,
    faSymfony,
    faLinux,
} from '@fortawesome/free-brands-svg-icons'
library.add(faXing, faInstagram, faLinkedin, faGitlab, faPhp, faJava, faNode, faJs, faDocker, faVuejs, faAws, faSymfony, faLinux)

Vue.component('fa', FontAwesomeIcon)

Vue.use(VueRouter)
Vue.use(Buefy)
Vue.config.productionTip = false

new Vue({
    el: '#app',
    router,
    render: (h) => h(App),
}).$mount('#app')
