import VueRouter, { RouteConfig } from 'vue-router'
import Home from '@/views/Home.vue'
import DataPrivacy from '@/views/DataPrivacy.vue'

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/dataprivacy',
        name: 'Data Privacy',
        component: DataPrivacy,
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    linkActiveClass: 'is-active',
    linkExactActiveClass: 'is-active',
    routes,
})

export default router
